<template>
  <div>
    <v-container>
      <div class="card-header px-0">
        <div>
          <kassaTopshiriqnoma />
        </div>
        <!-- <div class="row align-items-center">
          <div class="col-lg-10 col-xl-8">
            <div class="row align-items-center">
              <div class="col-md-2 my-2 my-md-0">
                <div class="input-icon">
                  <input
                    type="text"
                    v-model="amount"
                    v-debounce:400ms="myFn"
                    class="form-control"
                    placeholder="Summa"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-md-2 my-2 my-md-0">
                <div class="d-flex align-items-center">
                  <select
                    v-model="order_type"
                    @change="myFn"
                    class="form-control"
                  >
                    <option value="" selected disabled>Turi</option>
                    <option value="1">Kirim</option>
                    <option value="2">Chiqim</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 my-2 my-md-0">
                <div class="d-flex align-items-center">
                  <select
                    v-model="type_statetransfer"
                    @change="myFn"
                    class="form-control"
                  >
                    <option value="" selected disabled>Transfer nomi</option>
                    <option value="1"
                      >Mijozlardan kursatilgan hizmat uchun tushumlar</option
                    >
                    <option value="2">Korporativ Kartalardan tushumlar</option>
                    <option value="3">Korporativ kartani tuldirish</option>
                    <option value="4">Yoni'gi uchun xarajatlar</option>
                    <option value="5">Mijozlarga tulovlar</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 my-2 my-md-0">
                <div class="d-flex align-items-center">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <input
                        type="text"
                        v-model="oper_date__range_1"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        class="form-control"
                        placeholder="Sana(boshlanish)"
                      />
                    </template>
                    <v-date-picker
                      @input="myFn"
                      v-model="oper_date__range_1"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false"
                        >Cancel</v-btn
                      >
                      <v-btn text color="primary" @click="$refs.menu.save(date)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
              <div class="col-md-2 my-2 my-md-0">
                <div class="d-flex align-items-center">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <input
                        type="text"
                        v-model="oper_date__range_2"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        class="form-control"
                        placeholder="Sana(tugash)"
                      />
                    </template>
                    <v-date-picker
                      @input="myFn"
                      v-model="oper_date__range_2"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu2 = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(date)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-xl-4 mr-auto mt-5 mt-lg-0 text-right">
            <v-btn @click="reset" color="error">Tozalash</v-btn>
          </div>
        </div> -->
      </div>
    </v-container>
    <Table />
  </div>
</template>
<script>
import kassaTopshiriqnoma from './kassaTopshiriqnoma'
import Table from './Page'
export default {
  data: () => ({
    // amount: "",
    // order_type: "",
    // type_statetransfer: "",
    // menu: false,
    // oper_date__range_1: "",
    // menu2: false,
    // date: new Date().toISOString().substr(0, 10),
    // oper_date__range_2: "",
    // type_payment: "",
    // paymentOrderType: ""
  }),
  created() {},
  methods: {
    // reset() {
    //   this.type_payment = "";
    //   this.oper_date__range_1 = "";
    //   this.oper_date__range_2 = new Date().toISOString().substr(0, 10);
    //   this.order_type = "";
    //   this.type_statetransfer = "";
    //   this.amount = "";
    //   this.$store.commit("setFilterData", {
    //     data: undefined,
    //     path: ""
    //   });
    //   this.myFn();
    // },
    // myFn() {
    //   const data = {};
    //   data.oper_date__range = [];
    //   data.payment_source = 1;
    //   if (this.order_type !== "") {
    //     data.order_type = this.order_type;
    //   }
    //   if (this.type_statetransfer !== "") {
    //     data.type_statetransfer = this.type_statetransfer;
    //   }
    //   if (this.amount !== "") {
    //     data.amount = this.amount;
    //   }
    //   if (this.oper_date__range_1 !== "") {
    //     data.oper_date__range[0] = this.oper_date__range_1;
    //     data.oper_date__range[1] = this.oper_date__range_2;
    //   } else {
    //     delete data.oper_date__range;
    //   }
    //   this.$store.commit("setFilterData", {
    //     data: data,
    //     path: this.$route.path
    //   });
    //   this.$store.dispatch("vipiskaFilter", data);
    //   if (this.$route.path !== "/vipiska/bank/1") {
    //     this.$router.push("/vipiska/bank/" + "1");
    //   }
    // }
  },
  components: {
    kassaTopshiriqnoma,
    Table
  }
}
</script>
