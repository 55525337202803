<template>
  <div>
    <!-- <v-tab-item> -->
    <v-card>
      <v-card-text>
        <div>
          <v-data-table
            :headers="headers"
            :loading="isLoading"
            hide-default-footer
            :must-sort="true"
            :page.sync="page"
            class="row-pointer"
            single-select
            @click:row="rowClick"
            item-key="id"
            :items-per-page="itemsPerPage"
            :items="getAlltransactionsDaily"
            loading-text="Malumot yuklanmoqda..."
            no-data-text="Malumot topilmadi"
          >
            <template v-slot:[`item.index`]="{ item }">
              {{
                getAlltransactionsDaily
                  .map(function (x) {
                    return x.oper_date
                  })
                  .indexOf(item.oper_date) + 1
              }}
            </template>
            <template v-slot:[`item.debet`]="{ item }">
              <div
                v-for="summa in item.operations"
                :key="summa.order_type__code"
              >
                <div v-if="summa.order_type__code == '01'">
                  {{ summa.amount__sum }}
                </div>
              </div>
            </template>
            <template v-slot:[`item.creadet`]="{ item }">
              <div
                v-for="summa in item.operations"
                :key="summa.order_type__code"
              >
                <div v-if="summa.order_type__code == '02'">
                  {{ summa.amount__sum }}
                </div>
              </div>
            </template>

            <template v-slot:[`item.oper_date`]="{ item }">
              {{ item.oper_date | formatDate }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <Action
                :source="getPaymentSource.find((x) => x.code == '02').id"
                :index="item"
              ></Action>
            </template>
          </v-data-table>
        </div>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="Math.ceil(getAlltransactionsDaily.length / 10)"
          ></v-pagination>
        </div>
        <!-- <div class="text-left mt-5">
          <v-pagination
            v-model="page"
            :total-visible="7"
            @input="getPostData(page)"
            :length="Math.ceil(getCount.count / 10)"
          ></v-pagination>
        </div> -->
      </v-card-text>
    </v-card>
    <!-- </v-tab-item> -->
  </div>
</template>
<script>
import Action from './Action'
export default {
  components: {
    Action
  },
  data: () => ({
    counter: 0, // count the clicks
    timer: null, // Needs to be specified here so it can be accessed on both clicks
    selectedId: -1,
    page: 1,
    itemsPerPage: 10,
    headers: [
      { text: '#', value: 'index' },
      { text: 'Sana', value: 'oper_date' },
      { text: "Boshlang'ich qoldiq", value: 'starting_remain' },
      { text: 'Kirim', value: 'debet' },
      { text: 'Chiqim', value: 'creadet' },
      { text: 'Kunlik farq', value: 'daily_differ' },
      { text: 'Oxirgi qoldiq', value: 'ending_remain' },
      { text: '', value: 'action', sortable: false }
    ]
  }),
  beforeCreate() {
    // this.$store.dispatch("getTotalSum", "01");
    const data = {
      payment_source: '2'
    }
    this.$store.dispatch('getAlltransactionsDaily', data)
  },
  //   created() {
  //     this.page = parseInt(this.$route.params.id, 10);
  //     this.getPostData(this.page);
  //   },
  watch: {
    '$route.params.id': function (id) {
      this.page = parseInt(id, 10)
    }
  },
  filters: {
    formatDate: function (val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  computed: {
    getPaymentSource() {
      return this.$store.state.requests.paymentSource
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getAlltransactionsDaily() {
      return this.$store.state.requests.allTransactionsDaily
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    }
    // getPostData(value) {
    //   if (this.$store.state.requests.filterData.data !== undefined) {
    //     this.$store.dispatch("vipiskaFilterCashPerPage", {
    //       data: this.$store.state.requests.filterData.data,
    //       page: value
    //     });
    //     if (this.$route.path !== "/vipiska/bank/" + value) {
    //       this.$router.push("/vipiska/bank/" + value);
    //     }
    //   } else {
    //     const data = {
    //       payment_source: "01",
    //       page: value
    //     };
    //     this.$store.dispatch("getAllTransferListPagBank", data);
    //     if (this.$route.path !== "/vipiska/bank/" + value) {
    //     //   this.$router.push("/vipiska/bank/" + value);
    //     }
    //   }
    // }
  }
}
</script>
